import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'hearts_2_circle_fill_twilight_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="hearts_2_circle_fill_twilight_56"><linearGradient id="hearts_2_circle_fill_twilight_56_a" gradientUnits="userSpaceOnUse" x1="0" x2="56" y1="0" y2="56"><stop offset="0" stop-color="#ff4d87" /><stop offset=".521" stop-color="#9f40ff" /><stop offset="1" stop-color="#39f" /></linearGradient><circle cx="28" cy="28" fill="url(#hearts_2_circle_fill_twilight_56_a)" r="28" /><g fill="#fff"><path clip-rule="evenodd" d="M14 24.688C14 20.994 16.946 18 20.58 18c1.96 0 3.7.945 5.2 2.775C27.277 18.945 29.018 18 30.977 18c3.635 0 6.58 2.994 6.58 6.688 0 .646-.043 1.236-.149 1.796a7.066 7.066 0 00-2.565.907 7.038 7.038 0 00-3.6-.981 7.558 7.558 0 00-7.556 7.56c0 1.165.167 2.246.614 3.308l-2.562-2.026C15.473 30.298 14 28.424 14 24.688z" fill-rule="evenodd" /><path d="M31.244 29.41a4.558 4.558 0 00-4.556 4.56c0 2.548 1.02 3.825 5.359 7.203l2 1.557a1.297 1.297 0 001.593 0l2.001-1.557c4.34-3.378 5.36-4.655 5.36-7.203 0-2.518-2.04-4.56-4.557-4.56-1.357 0-2.562.644-3.6 1.892-1.038-1.248-2.243-1.892-3.6-1.892z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56Hearts2CircleFillTwilightProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56Hearts2CircleFillTwilight: FC<Icon56Hearts2CircleFillTwilightProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56Hearts2CircleFillTwilight as any).mountIcon = mountIcon;

export default Icon56Hearts2CircleFillTwilight;
