import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'picture_in_picture_out_16x9_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="picture_in_picture_out_16x9_outline_28"><g fill="currentColor"><path d="M10.956 4h4.088c1.363 0 2.447 0 3.321.071.896.074 1.66.227 2.359.583a6 6 0 012.622 2.622c.356.7.51 1.463.583 2.359.071.874.071 1.958.071 3.321V14a1 1 0 11-2 0v-1c0-1.416 0-2.419-.065-3.202-.063-.772-.182-1.244-.371-1.614a4 4 0 00-1.748-1.748c-.37-.189-.842-.308-1.613-.371C17.419 6 16.417 6 15 6h-4c-1.417 0-2.419 0-3.203.065-.771.063-1.243.182-1.613.371a4 4 0 00-1.748 1.748c-.189.37-.308.842-.371 1.613C4 10.581 4 11.583 4 13s0 2.419.065 3.203c.063.772.182 1.243.371 1.613a4 4 0 001.748 1.748c.37.189.842.308 1.613.371C8.581 20 9.583 20 11 20h1a1 1 0 110 2h-1.044c-1.363 0-2.447 0-3.321-.071-.896-.074-1.66-.227-2.359-.583a6 6 0 01-2.622-2.622c-.356-.7-.51-1.463-.583-2.359C2 15.491 2 14.407 2 13.044v-.088c0-1.363 0-2.447.071-3.321.074-.896.227-1.66.583-2.359a6 6 0 012.622-2.622c.7-.356 1.463-.51 2.359-.583C8.509 4 9.593 4 10.956 4z" /><path d="M10.414 11l3.293 3.293a1 1 0 01-1.414 1.414L9 12.414V14a1 1 0 11-2 0v-3.8A1.2 1.2 0 018.2 9H12a1 1 0 110 2zM15 20.2c0-1.12 0-1.68.218-2.108a2 2 0 01.874-.874C16.52 17 17.08 17 18.2 17h4.6c1.12 0 1.68 0 2.108.218a2 2 0 01.874.874C26 18.52 26 19.08 26 20.2v.6c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874C24.48 24 23.92 24 22.8 24h-4.6c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874C15 22.48 15 21.92 15 20.8z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PictureInPictureOut16x9OutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PictureInPictureOut16x9Outline: FC<Icon28PictureInPictureOut16x9OutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PictureInPictureOut16x9Outline as any).mountIcon = mountIcon;

export default Icon28PictureInPictureOut16x9Outline;
