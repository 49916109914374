import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'search_like_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="search_like_outline_56"><path clip-rule="evenodd" d="M21.468 9.598c-.501.14-.99.312-1.468.492a17.026 17.026 0 00-6 3.869l-.02.02-.025.024a17.075 17.075 0 00-2.192 2.704 17.096 17.096 0 00-1.02 1.798 1.485 1.485 0 01-.054.124c-.303.63-.565 1.268-.785 1.913l-.005.017c-.223.66-.403 1.325-.542 1.99-.53 2.56-.474 5.282.272 7.974 2.51 9.047 11.878 14.348 20.925 11.839.067-.019.133-.033.2-.042a17.094 17.094 0 005.668-2.887A17.153 17.153 0 0040.427 35h.007c.318-.512.612-1.046.88-1.6a1.5 1.5 0 112.701 1.303 20.03 20.03 0 01-2.841 4.35l9.989 9.989a1.5 1.5 0 01-2.121 2.121l-9.989-9.988c-5.849 5.03-14.348 6.419-21.74 2.853a19.918 19.918 0 01-9.795-10.372 19.858 19.858 0 01-1.293-4.645 19.925 19.925 0 01-.053-5.648 19.881 19.881 0 013.146-8.4 19.923 19.923 0 0111.348-8.256 1.5 1.5 0 11.802 2.891zM34.882 29.45l-2.94-2.265C25.592 22.294 24 20.321 24 16.354 24 12.292 27.324 9 31.425 9c1.762 0 3.383.494 4.83 1.457l.245.17.245-.17a8.538 8.538 0 014.353-1.445L41.575 9C45.675 9 49 12.292 49 16.354c0 3.967-1.592 5.94-7.942 10.831l-2.94 2.265a2.656 2.656 0 01-3.236 0zM31.425 12c1.161 0 2.197.314 3.145.94l.22.152a3 3 0 003.42 0l.22-.152a5.535 5.535 0 012.788-.93l.39-.01c2.456.018 4.392 1.987 4.392 4.354 0 1.45-.252 2.277-.996 3.277-.911 1.224-2.575 2.711-5.776 5.178l-2.728 2.1-2.728-2.1c-3.201-2.467-4.865-3.954-5.776-5.178-.744-1-.996-1.828-.996-3.277C27 13.976 28.954 12 31.425 12z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56SearchLikeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56SearchLikeOutline: FC<Icon56SearchLikeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56SearchLikeOutline as any).mountIcon = mountIcon;

export default Icon56SearchLikeOutline;
