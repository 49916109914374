import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'message_heart_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="message_heart_outline_28"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M14.019 5.5c-5.385 0-9.482 3.719-9.482 7.999 0 1.537.515 2.979 1.422 4.2a1 1 0 01.197.597c0 1.042-.273 2.052-.61 2.963a25.572 25.572 0 01-.704 1.675c2.056-.218 3.405-.877 4.218-1.834a1 1 0 011.114-.289c.58.218 1.19.388 1.826.505a1 1 0 01-.361 1.967 12.739 12.739 0 01-1.538-.382C8.656 24.25 6.557 24.898 3.989 25c-1.047.041-1.802-1.047-1.364-2.021l.27-.597c.276-.605.547-1.199.775-1.815.256-.694.43-1.349.475-1.966-1.017-1.48-1.608-3.228-1.608-5.101 0-5.66 5.29-9.999 11.482-9.999 4.247 0 8.012 2.013 10.004 5.085a1 1 0 11-1.678 1.088c-1.588-2.45-4.69-4.173-8.326-4.173z" /><path d="M19.137 12.824a4.752 4.752 0 012.438-.817l.267-.007A4.158 4.158 0 0126 16.158c0 2.242-.892 3.358-4.447 6.123l-1.647 1.281a1.476 1.476 0 01-1.812 0l-1.647-1.28C12.892 19.515 12 18.4 12 16.157A4.158 4.158 0 0116.158 12c.986 0 1.895.28 2.705.824l.137.096zM24 16.158A2.158 2.158 0 0021.842 14c-.803 0-1.498.3-2.135.937l-.354.353a.5.5 0 01-.706 0l-.354-.353c-.637-.636-1.332-.937-2.135-.937A2.158 2.158 0 0014 16.158c0 1.41.584 2.14 3.675 4.545L19 21.733l1.845-1.438c2.555-2.02 3.12-2.763 3.153-4.015z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MessageHeartOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MessageHeartOutline: FC<Icon28MessageHeartOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MessageHeartOutline as any).mountIcon = mountIcon;

export default Icon28MessageHeartOutline;
