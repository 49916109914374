import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 24';
const id = 'chevron_compact_left_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24" id="chevron_compact_left_24"><path d="M11.293 7.706a1 1 0 000-1.412l-.088-.088a.997.997 0 00-1.414.002l-5.084 5.084a.998.998 0 000 1.416l5.084 5.084c.39.391 1.026.39 1.414.002l.088-.088a.995.995 0 000-1.412L7 12z" fill="#b8c1cc" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ChevronCompactLeftProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ChevronCompactLeft: FC<Icon24ChevronCompactLeftProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ChevronCompactLeft as any).mountIcon = mountIcon;

export default Icon24ChevronCompactLeft;
