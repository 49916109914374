import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'report_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="report_outline_56"><g fill="currentColor"><path clip-rule="evenodd" d="M17.06 5.318A4.5 4.5 0 0120.244 4h15.514a4.5 4.5 0 013.182 1.318l11.743 11.743A4.5 4.5 0 0152 20.243v15.514a4.5 4.5 0 01-1.318 3.182L38.939 50.682A4.5 4.5 0 0135.757 52H20.243a4.5 4.5 0 01-3.182-1.318L5.318 38.939A4.5 4.5 0 014 35.757V20.243a4.5 4.5 0 011.318-3.182zM20.244 7a1.5 1.5 0 00-1.061.44L7.439 19.181A1.5 1.5 0 007 20.242v15.515c0 .398.158.78.44 1.061l11.742 11.743a1.5 1.5 0 001.06.439h15.515a1.5 1.5 0 001.061-.44l11.743-11.742a1.5 1.5 0 00.44-1.06V20.242a1.5 1.5 0 00-.44-1.061L36.818 7.439A1.5 1.5 0 0035.758 7z" fill-rule="evenodd" /><path d="M30 36a2 2 0 10-4 0 2 2 0 004 0zM29.5 18a1.5 1.5 0 00-2.993-.145L26.5 18v11a1.5 1.5 0 002.993.145L29.5 29z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56ReportOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56ReportOutline: FC<Icon56ReportOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56ReportOutline as any).mountIcon = mountIcon;

export default Icon56ReportOutline;
