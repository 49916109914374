import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'search_like_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="search_like_outline_28"><g fill="currentColor"><path clip-rule="evenodd" d="M12 6.9C12 4.733 13.775 3 15.922 3c.973 0 1.834.336 2.573.937.738-.603 1.603-.937 2.583-.937C23.225 3 25 4.733 25 6.9c0 1.127-.306 2.018-1.031 2.916-.665.824-1.694 1.662-3.075 2.743l-.004.002-1.428 1.11c-.566.439-1.358.439-1.923 0l-1.43-1.11-.002-.002c-1.382-1.081-2.41-1.92-3.076-2.743C12.306 8.918 12 8.027 12 6.9zM15.922 5A1.915 1.915 0 0014 6.9c0 .675.152 1.122.587 1.66.494.612 1.327 1.308 2.75 2.423l.002.001 1.161.901 1.16-.901.002-.001c1.424-1.115 2.257-1.811 2.751-2.423.434-.538.587-.985.587-1.66 0-1.043-.86-1.9-1.922-1.9-.639 0-1.228.27-1.8.979a1 1 0 01-1.55.008C17.136 5.267 16.542 5 15.922 5z" fill-rule="evenodd" /><path d="M9.549 6.908A1 1 0 008.56 5.17a9 9 0 1010.057 14.862l4.675 4.676a1 1 0 001.414-1.414l-4.675-4.675c.356-.446.672-.927.94-1.436a1 1 0 10-1.771-.93 7 7 0 11-9.652-9.343z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SearchLikeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SearchLikeOutline: FC<Icon28SearchLikeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SearchLikeOutline as any).mountIcon = mountIcon;

export default Icon28SearchLikeOutline;
